<template>
    <a-modal
            title="Date visite"
            :visible="visible"
            @cancel="handleCancel"
            okText="Valider"
            @ok="handleOk"
            :okButtonProps="{ props: { htmlType: 'submit' } }"
    >
        <a-form
                :layout="formLayout"
                id="visite_form"
                name="visite_form"
                class="adherent_form"
                :form="form"
        >
            <a-row>
                <a-col>
                    <a-form-item v-bind="formItemLayout" label="Dt. visite :">
                        <a-date-picker

                                :format="dateFormat"
                                v-decorator="[
                'dt_reel_visite',
                {
                  rules: [
                    {
                      type: 'object',
                      required: true,
                      message: 'Date  est obligatoire!',
                    },
                  ],
                },
              ]"
                        />
                    </a-form-item>
                </a-col>
                <a-col>
                    <a-form-item v-bind="formItemLayout" label="Rapport visite :">
                        <textarea v-decorator="[
                'rapport',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Rapport est obligatoire!',
                    },
                  ],
                },
              ]"/>
                    </a-form-item>
                </a-col>
            </a-row>
        </a-form>
        <template slot="footer">
            <a-button
                    key="submit"
                    type="primary"
                    :loading="processing"
                    @click="handleOk"
            >Valider
            </a-button
            >
        </template>
    </a-modal>
</template>
<script>
    export default {
        name: "VisiteValidationForm",
        mounted() {
            this.fillTheForm();
        },
        data() {
            return {
                formItemLayout: {
                    labelCol: {
                        xs: {span: 24},
                        sm: {span: 6},
                    },
                    wrapperCol: {
                        xs: {span: 24},
                        sm: {span: 16},
                    },
                },
                form: this.$form.createForm(this),
            };
        },
        props: {
            processing: {type: Boolean, default: () => false},
            visible: {type: Boolean, default: () => false},
            visite: {type: Object},
        },
        methods: {
            handleClose() {
                this.$emit("close");
            },
            handleCancel() {
                this.$emit("close");
            },
            handleOk() {
                this.$emit("create");
            },
            fillTheForm() {
                if (this.visite) {
                    this.$nextTick(() => {
                        this.form.setFieldsValue(this.visite);
                        this.form.setFieldsValue({dt_reel_visite: this.momentDate(this.visite.dt_reel_visite)});
                    });
                }
            },
        },
    };
</script>
